import React from "react";
import appStore from '../assets/app_store_black_en.svg';
import playStore from '../assets/play_store_black_en.svg'

export default function Stores(props) {
    return (
            <div className="social-container">
                <a href="https://apps.apple.com/fr/app/naosuu/id1632412901" target="_blank" rel="noreferrer"
                    className="apple social">
                    <img src={appStore} className="appstorelogo" alt="AppleStore"/>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.naosuu.naosuu" target="_blank" rel="noreferrer"
                    className="google social">
                    <img src={playStore} className="playstorelogo" alt="GooglePlayStore"/>
                </a>
            </div>
    );
}