import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInstagram
  } from "@fortawesome/free-brands-svg-icons";

export default function SocialFollow(props) {
  var targetUrl = "https://www.instagram.com/naosuu_app"
  if (props.lang === "fr") {
    targetUrl = "https://www.instagram.com/naosuu_fr"
  } else {
    targetUrl = "https://www.instagram.com/naosuu_app"
  }

  return (
      <div className="social-container">
          <a href={targetUrl} target="_blank" rel="noreferrer"
              className="instagram social">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
      </div>
  );
}