import logo from './assets/logo.svg';
import SocialFollow from "./components/SocialFollow"
import Stores from "./components/Stores";
import React, { useState } from 'react'
import './App.css';
import Divider from '@mui/material/Divider';
import LanguageIcon from '@mui/icons-material/Language';
import Button from '@mui/material/Button'

import textContent from './assets/text.json'

function formatLangSwitcher(lang) {
  if (lang === "en") {
    return(
      <span className="Language-text">FR <b>EN</b></span>
    )
  } else {
    return(
      <span className="Language-text"><b>FR</b> EN</span>
    )
  }
}

function getText(id, lang) {
  if (textContent[id]["type"] === "divider") {
    const raw = textContent[id][lang]
    return(
      <Divider className='Divider' textAlign="left"><b><i>{raw}</i></b></Divider>
    )
  } else {
    const raw = textContent[id][lang]
    return(
      <p className="App-content" dangerouslySetInnerHTML={{__html: raw}}></p>
    )
  }
}

function App() {

  var browser_lang = navigator.language || navigator.userLanguage
  if (browser_lang.includes('fr')) {
    browser_lang = "fr"
  } else {
    browser_lang = "en"
  }

  const [lang, setLang] = useState(browser_lang);

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-language">
          <Button variant="text" onClick={() => setLang(lang === "fr" ? "en" : "fr")}><LanguageIcon className="Language-icon"/>{ formatLangSwitcher(lang)}</Button>
        </div>
        <img src={logo} className="App-logo" alt="logo" />
        <Stores lang={lang} />
      </header>

      {getText("meilleur_demain",lang)}
      {getText("a_quoi_sert",lang)}
      {getText("a_quoi_sert_content",lang)}
      {getText("utilisation",lang)}
      {getText("utilisation_content",lang)}
      {getText("no_photo",lang)}
      {getText("no_photo_content",lang)}
      {getText("save_the_world",lang)}
      {getText("save_the_world_content",lang)}
      {getText("improve",lang)}
      {getText("improve_content",lang)}

      <Stores lang={lang} />
      <SocialFollow lang={lang} />
      <div className="contact-container">
          <p className="contact-content">Naosuu SAS</p>
          <p className="contact-content">SIRET: 90775161400013</p>
          <a href="mailto:contact@naosuu.com" target="_blank" rel="noreferrer"
              className="contact-content">
              <p>{textContent["contact_content"][lang]}</p>
          </a>
      </div>
    </div>
  );
}

export default App;
